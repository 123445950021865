import { TPaths, TPrivateKeys, TPublicKeys } from "interfaces/Route";
import { privatePaths, publicPaths } from "routes/paths";
import { TUserRole } from "types/user";

export function getPath<T = TPaths>(key: T, isTitle = false) {
  if (typeof key === "string") {
    if (key in privatePaths) {
      let k = key as TPrivateKeys;
      return privatePaths[k][isTitle ? "title" : "path"];
    } else if (key in publicPaths) {
      let k = key as TPublicKeys;
      return publicPaths[k][isTitle ? "title" : "path"];
    }
  }
  return "";
}

export function getFullPathInfo<T = TPaths>(key: T) {
  if (typeof key === "string") {
    if (key in privatePaths) {
      let k = key as TPrivateKeys;
      return privatePaths[k];
    } else if (key in publicPaths) {
      let k = key as TPublicKeys;
      return publicPaths[k];
    }
  }
  return publicPaths["signin"];
}

export function getKeyFromTitle(title: string): TPaths {
  const keys = [
    ...Object.keys(privatePaths),
    ...Object.keys(publicPaths),
  ] as TPaths[];
  const keyIndex = [
    ...Object.values(privatePaths),
    ...Object.values(publicPaths),
  ].findIndex((i) => i.title === title);

  return keys[keyIndex];
}

type THomeRoute = "contracts" | "signin" | string;

export function getHomeRoute(role: TUserRole, isKey?: boolean): THomeRoute {
  switch (role) {
    case "admin":
      return isKey ? "users" : getPath("users_clients");
    case "employee":
      return isKey ? "users" : getPath("users_clients");
    case "client":
      return isKey ? "contracts" : getPath("contracts");
    default:
      return isKey ? "signin" : getPath("signin");
  }
}

