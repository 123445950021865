import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ShareRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M20 9.33333L20 4M20 4H14.6667M20 4L12.8889 11.1111M10.2222 5.77778H8.26667C6.77319 5.77778 6.02646 5.77778 5.45603 6.06843C4.95426 6.32409 4.54631 6.73204 4.29065 7.2338C4 7.80423 4 8.55097 4 10.0444V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20H13.9556C15.449 20 16.1958 20 16.7662 19.7094C17.268 19.4537 17.6759 19.0457 17.9316 18.544C18.2222 17.9735 18.2222 17.2268 18.2222 15.7333V13.7778" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </IconWrapper>
  );
}

export default ShareRegular;