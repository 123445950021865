import { useMemo, useCallback, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPath as getPathFn } from "utils/pathHandler";
import { privatePaths } from 'routes/paths';
import { TPaths } from "interfaces/Route";

type THS = {
  [key: string]: any;
};

function useRouteHandler<T = THS>() {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [customTitle, setCustomTitle] = useState("");
  const historyState = useMemo(() => state as T, [state]);

  const title = useMemo(
    () =>
      customTitle ||
      Object.values(privatePaths)
        .find(
          (i) =>
            i.path ===
            pathname.replace(
              /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
              ":id"
            )
        )?.title ||
      "",
    [pathname, customTitle]
  );

  const getPath = useCallback(getPathFn, []);

  const to = useCallback(
    (
      key: TPaths | number,
      options?: {
        replace?: boolean;
        state?: any;
        id?: string;
        param?: {
          n: string;
          value: string;
        }
        replaceFn?: (str: string) => string;
      }
    ) => {
      if (typeof key === "number") return navigate(key);
      let path = getPath(key);
      if (options?.param?.n){
        path = `${path}?${options.param.n}=${options.param?.value}`
      }
      if (options?.id) {
        path = path.replace(":id", options.id);
        options.id = undefined;
      } else if (options?.replaceFn) path = options.replaceFn(path);
      return navigate(path, options);
    },
    [navigate, getPath]
  );

  const handleClickHref = useCallback(
    (
      e: React.MouseEvent<HTMLAnchorElement>,
      replace = false,
      state = {} as any
    ) => {
      e.preventDefault();
      if(!e.currentTarget.href){
        return;
      }
      const url = new URL(e.currentTarget.href);
      navigate(url.pathname + url.search, {
        replace,
        state,
      });
    },
    [navigate]
  );

  useEffect(() => setCustomTitle(""), [pathname]);

  // @ts-ignore
  window.rcnavigate = navigate;

  return {
    title,
    pathname,
    historyState,
    getPath,
    to,
    handleClickHref
  };
}

export default useRouteHandler;
