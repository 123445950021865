const all_roles = ["admin", "employee", "client"];
const admin_employee = ["admin", "employee"];

export const privatePaths = {
    contract: {
        path: "/contract/:id",
        title: "Detalhes do contrato",
        role: 'client',
    },
    contracts: {
        path: "/contracts",
        title: "Seus Contratos",
        role: 'client',
    },
    users_clients: {
        path: "/users_clients",
        title: "Clientes",
        role: admin_employee,
    },
    clientDetails: {
        path: "/client/:id",
        title: "Detalhes",
        role: admin_employee,
    },
    serviceUnavailable: {
        path: "/503",
        title: "Erro",
        role: all_roles,
    }
  };
  