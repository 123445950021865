import { extendTheme } from "@stardust-ds/react";

const config = {
  color: {
    primary: {
      pure: "#1A48FA",
      lighter: "#E5F4FF",
      light: "#AFDBFF",
      medium: "#1A71FA",
      dark: "#231F20",
      darker: "#011D33",
    },
    secondary: {
      pure: "#1A99FA",
      lighter: "#E5FCFF",
      light: "#AFF4FF",
      medium: "#28A6BA",
      dark: "#026777",
      darker: "#012C33",
    },
    positive: {
      pure: "#1ECB4F",
      light: "#B0E1BD",
      medium: "#1D8D3C",
      dark: "#125A26",
    },
    negative: {
      pure: "#FF3541",
      light: "#FFBEC2",
      medium: "#BE3A42",
      dark: "#7A252A",
    },
    status: {
      positive: "#1ECB4F",
      negative: "#FF3541",
      alert: "#FFAE00",
      neutral: {
        1: "#0066FF",
        2: "#3B454F",
        3: "#B83EE3",
        4: "#00344A",
      },
      "alert-2": "#FF7E35",
    },
  },
  media_query: {
    mobile: "580px",
    tablet: "800px",
  },
};

export const theme = extendTheme(config);
