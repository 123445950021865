import React from "react";
import Route from "models/Route";
import { getFullPathInfo } from "utils/pathHandler";

const Contracts = React.lazy(() => import("pages/private/client/Contracts"));
const Contract = React.lazy(() => import("pages/private/client/Contract"));
const Users_Clients = React.lazy(() => import("pages/private/admin/Users_Clients"));
const Client = React.lazy(() => import("pages/private/admin/Client"));
const ServiceUnavailable = React.lazy(() => import("pages/public/ServiceUnavailable"));

const privates: Route[] = [
  new Route({ ...getFullPathInfo("contracts"), Component: Contracts }),
  new Route({ ...getFullPathInfo("contract"), Component: Contract }),
  new Route({ ...getFullPathInfo("users_clients"), Component: Users_Clients }),
  new Route({ ...getFullPathInfo("clientDetails"), Component: Client }),
  new Route({ ...getFullPathInfo("serviceUnavailable"), Component: ServiceUnavailable }),
];

export default privates;