import React, { useCallback, useState } from 'react';

import { useTheme, Button } from '@stardust-ds/react';

import { NavbarWrapper, Logo, ToggleButton, NavBtns, NavLink} from './styles';
import useStore from 'hooks/useStore';

interface IProps {
  children: JSX.Element | React.ReactNode;
  displayNav?: string;
}

const ClientWrapper: React.FC<IProps> = ({ children }) => {
  const signout = useStore(state => state.signout);
  const theme = useTheme();
  const [active, setActive] = useState(false);
  
  const handleActive = () => {
    setActive(!active)
  };

  const handleLogout = useCallback(async () => {
    try {
      await signout()
    } catch(err) {
        //
    }
  }, [signout]);
  return (
    <>
    <NavbarWrapper>
      <Logo>
        <div className='logo-container'>
          <img src={require('assets/images/logo_mega_3.webp')} />
        </div>
      </Logo>
      {/* <NavItems isActive={active} /> */}
      <NavBtns>
        <div>
          <Button bgColor={theme.brand.color.secondary.pure}>FALE AGORA</Button>
        </div>
        <div>
          <NavLink onClick={handleLogout}>
            Sair
          </NavLink>
        </div>
      </NavBtns>
      <ToggleButton onClick={handleActive}>&#9776;</ToggleButton>
    </NavbarWrapper>
      {children}
    </>
  );
}

export default ClientWrapper;