import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BranchBuildingRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8 11.4H16.64C17.5361 11.4 17.9841 11.4 18.3264 11.5744C18.6274 11.7278 18.8722 11.9726 19.0256 12.2736C19.2 12.6159 19.2 13.0639 19.2 13.96V19.4M12.8 19.4V7.56C12.8 6.66392 12.8 6.21587 12.6256 5.87362C12.4722 5.57256 12.2274 5.32779 11.9264 5.17439C11.5841 5 11.1361 5 10.24 5H7.36C6.46392 5 6.01587 5 5.67362 5.17439C5.37256 5.32779 5.12779 5.57256 4.97439 5.87362C4.8 6.21587 4.8 6.66392 4.8 7.56V19.4M20 19.4H4M7.6 8.2H10M7.6 11.4H10M7.6 14.6H10" stroke="#1A48FA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </IconWrapper>
  );
}

export default BranchBuildingRegular;