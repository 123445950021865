import { INVALID_FORMAT, REQUIRED, SIZE } from "constants/YUP_MESSAGES";
import * as Yup from "yup";

const INVALID_PASSWORD = "A senha deve conter letra minúscula, maiúscula e um número e mínimo de 8 caracteres.";

export const emailSchema = Yup.object().shape({
  email: Yup.string().email(INVALID_FORMAT).required(REQUIRED),
});

const username = Yup
.string()
.test('login', 'Digite um CPF ou CNPJ válido', function (value) {
  const isCPFOrCNPJ = Yup
    .string()
    .matches(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/, 'Digite um CPF ou CNPJ válido')
    .isValidSync(value);
  return isCPFOrCNPJ;
})
.required('CPF ou CNPJ é obrigatório');

export const usernameSchema = Yup.object().shape({
  username: username,
})

export const loginSchema = Yup.object().shape({
    username: username,
    password: Yup.string().min(4, SIZE).required(REQUIRED),
});

export const newPasswordSchema = Yup.object().shape({
    password: Yup
        .string()
        .min(8, SIZE)
        .required(REQUIRED)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, INVALID_PASSWORD)
        ,
    confirmPassword: Yup.string()
      .required(REQUIRED)
      .oneOf([Yup.ref("password"), ""], "As senhas não conferem"),
});